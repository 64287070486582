<template>
    <painel titulo="Expressos - Atendimentos" icone="pi pi-comments" :refreshFunction="atualizar" v-show="mostrarListagem">
        <Panel header="Filtros" :toggleable="true" class="filtro">
            <div class="formgrid grid p-fluid mt-2">
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Data Atendimento (DE)</label>
                    <Calendar v-model="dataAtendimentoDe" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                </div>
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Data Atendimento (ATÉ)</label>
                    <Calendar v-model="dataAtendimentoAte" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                </div>
            </div>
        </Panel>
        <msg-box :itens="mensagens"></msg-box>
        <tabela
            :data="atendimentos"
            dataKey="atendimentoId"
            :globalFilterFields="['codAtendimento', 'descricaoAtendimento', 'cpf', 'cnpj', 'nomeCpf', 'nomeCnpj', 'statusDescr']"
            :filtersProp="filtros"
            :filterCallBack="dadosFiltrados"
            stateKey="dt-atendimentos"
        >
            <template #botoes>
                <btn-integrar
                    :atendimentosId="atendimentosSelecionados"
                    @obterAtendimentosAtualizados="obterAtendimentosAtualizados()"
                    @atualizarMensagens="atualizarMensagens($event)"
                ></btn-integrar>
                <btn-integrar
                    :atendimentosId="atendimentosSelecionados"
                    tipo="pf"
                    @obterAtendimentosAtualizados="obterAtendimentosAtualizados()"
                    @atualizarMensagens="atualizarMensagens($event)"
                ></btn-integrar>
                <btn-exportar :atendimentos="atendimentosFiltrados"></btn-exportar>
                <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined mr-2" @click="clearFilter()" />
            </template>
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <div class="white-space-nowrap">
                            <Checkbox
                                name="atendimentos"
                                :value="slotProps.data.atendimentoId"
                                v-model="atendimentosSelecionados"
                                v-if="mostrarChkBox(slotProps.data)"
                                class="mr-1"
                            />
                            <btn-detalhar :atendimento="slotProps.data"></btn-detalhar>
                        </div>
                    </template>
                </Column>
                <Column field="dataCadastro" header="Recebido em" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataCadastro, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
                <Column
                    field="unidadeAtendimento"
                    header="Unidade Atendimento"
                    :sortable="true"
                    filterField="codUnidadeAtendimento"
                    :showFilterMatchModes="false"
                >
                    <template #body="slotProps">
                        {{ slotProps.data.unidadeAtendimento }}
                    </template>
                    <template #filter="{ filterModel }">
                        <MultiSelect
                            v-model="filterModel.value"
                            :options="unidadesAtendimento"
                            optionLabel="descricao"
                            optionValue="codigo"
                            placeholder="Qualquer"
                            class="p-column-filter"
                        >
                        </MultiSelect>
                    </template>
                </Column>
                <Column field="codAtendimento" header="Cód. Atendimento" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.codAtendimento }}
                    </template>
                </Column>
                <Column field="dataAtendimento" header="Data Atendimento" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataAtendimento, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
                <Column field="cpf" header="CPF" :sortable="true">
                    <template #body="slotProps">
                        <div class="white-space-nowrap">
                            {{ slotProps.data.cpf }}
                        </div>
                        <status
                            :status="slotProps.data.cpfSasOk ? 'SAS OK' : 'SAS NOK'"
                            v-if="mostrarStatusSas(slotProps.data.cpf, slotProps.data.status)"
                        ></status>
                        <status status="CPF NÃO RECEBIDO" v-if="!slotProps.data.cpf"></status>
                    </template>
                </Column>
                <Column field="nomeCpf" header="Nome CPF" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.nomeCpf }}
                    </template>
                </Column>
                <Column field="cnpj" header="CNPJ" :sortable="true">
                    <template #body="slotProps">
                        <div class="white-space-nowrap">
                            {{ slotProps.data.cnpj }}
                        </div>
                        <status
                            :status="slotProps.data.cnpjSasOk ? 'SAS OK' : 'SAS NOK'"
                            v-if="mostrarStatusSas(slotProps.data.cnpj, slotProps.data.status)"
                        ></status>
                        <status status="CNPJ NÃO RECEBIDO" v-if="!slotProps.data.cnpj"></status>
                    </template>
                </Column>
                <Column field="nomeCnpj" header="Nome CNPJ" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.nomeCnpj }}
                    </template>
                </Column>
                <Column field="descricaoAtendimento" header="Descrição Atendimento" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.descricaoAtendimento }}
                    </template>
                </Column>
                <Column field="statusDescr" header="Status" :sortable="true" filterField="status" :showFilterMatchModes="false">
                    <template #body="slotProps">
                        <status :status="slotProps.data.statusDescr"></status>
                    </template>
                    <template #filter="{ filterModel }">
                        <MultiSelect
                            v-model="filterModel.value"
                            :options="statusLista"
                            optionLabel="statusDescr"
                            optionValue="status"
                            placeholder="Qualquer"
                            class="p-column-filter"
                        >
                        </MultiSelect>
                    </template>
                </Column>
                <Column field="codStatus" header="Cód. Status" :sortable="true" filterField="codStatus" :showFilterMatchModes="false">
                    <template #filter="{ filterModel }">
                        <MultiSelect v-model="filterModel.value" :options="codStatusLista" placeholder="Qualquer" class="p-column-filter"> </MultiSelect>
                    </template>
                </Column>
                <Column field="dataCadastro" header="Atualizado em" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataAtualizacao, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import AtendimentosServices from './services';
import BtnDetalhar from './BtnDetalhar';
import { atendimentoStatus, atendimentoStatusLista } from './AtendimentoStatus';
import { FilterMatchMode } from 'primevue/api';
import BtnExportar from './BtnExportar';
import BtnIntegrar from './BtnIntegrar';

export default {
    components: {
        BtnDetalhar,
        BtnExportar,
        BtnIntegrar,
    },

    data() {
        return {
            atendimentos: null,
            dataAtendimentoAte: null,
            dataAtendimentoDe: null,
            carregado: false,
            filtros: null,
            atendimentosFiltrados: [],
            statusLista: [...atendimentoStatusLista],
            atendimentosSelecionados: [],
            mensagens: [],
        };
    },

    methods: {
        obterAtendimentos() {
            this.$store.dispatch('addRequest');
            let filtroDto = {
                dataAtendimentoDe: this.$moment(this.dataAtendimentoDe).format('YYYY-MM-DD'),
                dataAtendimentoAte: this.$moment(this.dataAtendimentoAte).format('YYYY-MM-DD'),
            };
            AtendimentosServices.obterTodos(filtroDto).then((response) => {
                if (response?.success) {
                    this.atendimentos = response.data;
                } else {
                    this.atendimentos = null;
                }
                if (!this.carregado) {
                    this.carregado = true;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterAtendimentos();
        },

        mostrarStatusSas(dado, status) {
            return dado && status != atendimentoStatus.RECEBIDO;
        },

        clearFilter() {
            this.filtros = null;
            this.iniciarFiltros();
        },

        iniciarFiltros() {
            this.filtros = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                status: { value: null, matchMode: FilterMatchMode.IN },
                codUnidadeAtendimento: { value: null, matchMode: FilterMatchMode.IN },
                codStatus: { value: null, matchMode: FilterMatchMode.IN },
            };
        },

        dadosFiltrados(filterData) {
            this.atendimentosFiltrados = [...filterData.filteredValue];
        },

        mostrarChkBox(atendimento) {
            return (
                (atendimento.status == atendimentoStatus.RECEBIDO ||
                    atendimento.status == atendimentoStatus.PRONTOPARAINTEGRACAO ||
                    atendimento.status == atendimentoStatus.INDISPONIVELPARAINTEGRACAO ||
                    atendimento.status == atendimentoStatus.ERRONAINTEGRACAO) &&
                this.$temAcessoView('EXPR-ATEN-02')
            );
        },

        obterAtendimentosAtualizados() {
            this.atendimentosSelecionados = [];
            this.obterAtendimentos();
        },

        atualizarMensagens(msgs) {
            this.mensagens = [...msgs];
        },
    },

    created() {
        this.dataAtendimentoAte = new Date();
        this.dataAtendimentoDe = new Date(this.$moment(this.dataAtendimentoAte).subtract(1, 'months').format('YYYY-MM-DD'));
        this.obterAtendimentos();
        // this.iniciarFiltros();
    },

    mounted() {
        this.iniciarFiltros();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'Expressos_Atendimentos';
        },

        yearRange() {
            let anoFinal = new Date().getFullYear();
            let anoInicial = anoFinal - 120;
            return `${anoInicial}:${anoFinal}`;
        },

        unidadesAtendimento() {
            const result = [];
            const map = new Map();
            for (const item of this.atendimentos) {
                if (!map.has(item.codUnidadeAtendimento)) {
                    map.set(item.codUnidadeAtendimento, true); // set any value to Map
                    result.push({
                        codigo: item.codUnidadeAtendimento,
                        descricao: item.unidadeAtendimento,
                    });
                }
            }
            return result;
        },

        codStatusLista() {
            const result = [];
            const map = new Map();
            for (const item of this.atendimentos) {
                if (!map.has(item.codStatus)) {
                    map.set(item.codStatus, true); // set any value to Map
                    result.push(item.codStatus);
                }
            }
            return result.sort();
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'Expressos_Atendimentos') {
                if (this.$store.getters.atualizar) {
                    this.obterAtendimentos();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },

        dataAtendimentoDe() {
            if (this.carregado) {
                this.obterAtendimentos();
            }
        },

        dataAtendimentoAte() {
            if (this.carregado) {
                this.obterAtendimentos();
            }
        },
    },
};
</script>

<style>
.filtro .p-panel-header {
    background-color: rgb(212, 246, 255) !important;
}
</style>
