<template>
    <Button
        label="Exportar"
        class="p-button-secondary mr-2"
        icon="pi pi-download"
        @click="toggle"
        v-if="mostrarBtnExportar"
        :disabled="desabilitarBtn"
        aria-haspopup="true"
        aria-controls="overlay_menu"
    />
    <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
</template>

<script>
import AtendimentosServices from './services';

export default {
    props: {
        atendimentos: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            items: [
                {
                    label: 'Excel',
                    icon: 'pi pi-file-excel',
                    command: () => {
                        this.exportar('xlsx');
                    },
                },
                {
                    label: 'PDF',
                    icon: 'pi pi-file-pdf',
                    command: () => {
                        this.exportar('pdf');
                    },
                },
            ],
        };
    },

    methods: {
        toggle(event) {
            this.$refs.menu.toggle(event);
        },

        exportar(tipo) {
            this.$store.dispatch('addRequest');
            let atendimentosDto = {
                tipoExportacao: tipo,
                atendimentosId: this.atendimentos.map((c) => c.atendimentoId),
            };
            AtendimentosServices.exportar(atendimentosDto).then((response) => {
                if (response?.success) {
                    let nomeArquivo = `Listagem_Atendimentos_${this.$moment(new Date()).format('DD-MM-YYYY_HH-mm')}.${tipo}`;
                    this.$download(response.data, nomeArquivo);
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Atendimentos',
                        detail: 'Falha ao exportar listagem dos atendimentos',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    computed: {
        mostrarBtnExportar() {
            return this.$temAcessoView('EXPR-ATEN-01');
        },

        desabilitarBtn() {
            return !this.atendimentos || this.atendimentos.length === 0;
        },
    },
};
</script>
