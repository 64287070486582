<template>
    <font-awesome-icon icon="info-circle" @click="toDetalhar()" size="lg" class="icon-button p-text-primary" title="Detalhar" v-if="$temAcessoView('EXPR-ATEN-00')" />
</template>

<script>
export default {
    props: {
        atendimento: {
            type: Object,
        },
    },

    methods: {
        toDetalhar() {
            this.$router.push({
                name: 'Expressos_Atendimentos_Detalhar',
                params: {
                    id: this.atendimento.atendimentoId,
                },
            });
        },
    },
};
</script>
