<template>
    <Button
        :label="`Integrar${tipoPF ? ' PF' : ''}`"
        icon="pi pi-check-circle"
        @click="confirmar()"
        v-if="mostrarBtn"
        class="mr-2"
        :class="{ 'p-button-warning': tipoPF }"
    />
</template>

<script>
import AtendimentosService from './services';

export default {
    props: {
        atendimentosId: {
            type: Array,
        },

        tipo: {
            type: String,
            default: () => null,
        },
    },

    emits: ['obterAtendimentosAtualizados'],

    methods: {
        confirmar() {
            this.$confirm.require({
                message: `Tem certeza que deseja integrar os atendimentos selecionados${this.tipoPF ? ' apenas com a pessoa física' : ''}?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.integrar();
                },
            });
        },

        integrar() {
            this.$store.dispatch('addRequest');
            let atendimentosIdDto = {
                atendimentosId: this.atendimentosId,
            };
            if (this.tipo) {
                atendimentosIdDto.tipoIntegracao = this.tipo;
            }
            AtendimentosService.integrar(atendimentosIdDto).then((response) => {
                if (response?.success) {
                    this.$emit('atualizarMensagens', response.data);
                    this.$emit('obterAtendimentosAtualizados');
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    computed: {
        mostrarBtn() {
            let atendimentosOk = this.atendimentosId && this.atendimentosId.length > 0;
            if (this.tipoPF) {
                return this.$temAcessoView('EXPR-ATEN-03') && atendimentosOk;
            }
            return this.$temAcessoView('EXPR-ATEN-02') && atendimentosOk;
        },

        tipoPF() {
            return this.tipo == 'pf';
        },
    },
};
</script>
